import React, { Component } from "react";
import { Link } from "react-router-dom";
import Slider from "react-slick";
class AgencyAbout extends Component {
  render() {
    const settings = {
      infinite: true,
      speed: 1500,
      arrows: false,
      autoplay: true,
      className: "about_img_slider",
      autoplaySpeed: 4000,
      dots: false,
      centerMode: false,
      slidesToShow: 3,
      slidesPerRow: 1,
      slidesToScroll: 1,
      responsive: [
        {
          breakpoint: 1651,
          settings: {
            slidesToShow: 2
          }
        },
        {
          breakpoint: 1451,
          settings: {
            slidesToShow: 2
          }
        },
        {
          breakpoint: 1351,
          settings: {
            slidesToShow: 2
          }
        },
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 2
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1
          }
        }
      ]
    };
    return (
      <section className="agency_about_area d-flex bg_color">
        <div className="col-lg-6 about_content_left ">
          <div className="about_content mb_30">
            <h2 className="f_size_30 f_700 l_height45">
              Siamo giovani talenti indipendenti, volenterosi, agguerriti.
            </h2>
            <p className="f_size_15 f_400 mb_40">
              È vero, <b>siamo molto aguerriti</b>. Ognuno di noi è
              specializzato in settori diversi, così da poter ricoprire tutte le
              esigenze nel mondo digital.
              <br />
              <Link
                style={{
                  color: "#fff",
                  textDecoration: "underline !important"
                }}
                to="/servizi"
              >
                Scopri i servizi che offriamo.
              </Link>
              <br />
              <br /> <b>Perchè siamo liberi professionisti?</b> Per la
              specializzazione e per il risultato finale: il nostro lavoro è la
              nostra passione, metteremo quindi tutta la nostra esperienza,
              energia e passione in ogni incarico ricevuto.
              <br />
              <br />
              <b>Siamo un gruppo compatto</b>, siamo nati per l'esigenza di
              completare le richieste della nostra clientela dando un risultato
              ottimale per ogni settore impiegato, lavoriamo in sinergia per
              darti un risultato che non ti aspetti.
              <br />
              <br />
              Abbiamo creato un network, dove diversi settori si uniscono, per
              condividere idee, clientela, esperienza. Siamo alla ricerca altri
              di talenti da aggiungere nel nostro network. Ti interessa?
            </p>
            <Link to="/contatti" className="about_btn">
              Vieni a trovarci!
            </Link>
          </div>
        </div>
        <div className="col-lg-6 about_img">
          <Slider {...settings}>
            <div style={{ width: "33%" }} className="about_item">
              <img src={require("../../img/teams/enrico.png")} alt="" />
              <div className="about_text">
                <span className="br"></span>
                <h5 className="f_size_18 l_height28 mb-0">
                  Enrico <br />
                  Founder & FullStack Dev @ YoungLabs
                </h5>
              </div>
            </div>

            <div style={{ width: "33%" }} className="about_item">
              <img src={require("../../img/teams/alberto.jpg")} alt="" />
              <div className="about_text">
                <span className="br"></span>
                <h5 className="f_size_18 l_height28 mb-0">
                  Alberto <br />
                  Founder & Digital Strategist @ Aob
                </h5>
              </div>
            </div>

            <div style={{ width: "33%" }} className="about_item">
              <img src={require("../../img/teams/luca.jpeg")} alt="" />
              <div className="about_text">
                <span className="br"></span>
                <h5 className="f_size_18 l_height28 mb-0">
                  Luca <br />
                  Worpress @ AoB
                </h5>
              </div>
            </div>
          </Slider>
        </div>
      </section>
    );
  }
}
export default AgencyAbout;
