const ServiceData = {
  STitle: "Siamo nativi digitali e sviluppiamo servizi del futuro",
  Service: [
    {
      id: 1,
      shape: "icon_shape1.png",
      emoji: "🚀",
      icon: "ti-rocket",
      text: "Web App Perfomanti",
      description:
        "Sviluppiamo applicativi web con le ultime tecnologie che offre il mondo della tecnologia. Siamo specializzati nello sviluppo di applicativi custom su tecnologie come React, NodeJs, Vue, MongoDb, Laravel, MySql"
    },
    {
      id: 2,
      shape: "icon_shape2.png",
      emoji: "🔀",
      icon: "ti-exchange-vertical",
      text: "Integrazioni",
      description:
        "Spesso accade che il tuo business digitale abbia bisogno di comunicare attraverso più servizi. Noi di YoungLabs siamo esperti in creare e integrare Api per connettere i tuoi sistemi col resto del mondo."
    },
    {
      id: 3,
      shape: "icon_shape3.png",
      emoji: "🤖",
      icon: "ti-reddit",
      text: "AI & BOT",
      description:
        "Dedichiamo gran parte del nostro lavoro a sviluppare soluzioni integrate a servizi di intelligenza artificiale come NLP, Image Recognition, Sentyment Analysis su dispositivi IoT, mobile e web. "
    },
    {
      id: 4,
      shape: "icon_shape4.png",
      emoji: "🤓",
      icon: "ti-blackboard",
      text: "Corsi di formazione",
      description:
        "Crediamo nella condivisione delle nostre conoscenze. Offriamo corsi di formazione di gruppo per programmazione base, intermedia e avanzata. Ci focalizziamo su 5 persone per volta."
    }
  ],
  agtext1: "Why I say old chap that is spiffing bodge, blag pardon.",
  agtext2: "Why I say old chap that is spiffing bodge, blag pardon.",
  agtext3: "Starkers dropped a clanger lurgy is cack excuse my French what.",
  pServiceTitle: "Our Services",
  pServiceDetails:
    "Why I say old chap that is, spiffing jolly good a load of old tosh spend a penny tosser arse over.!",
  PaymentService: [
    {
      id: 1,
      image: "icon4.png",
      title: "Safer",
      description: "Well at public scho cheeky bugger grub burke."
    },
    {
      id: 2,
      image: "icon7.png",
      title: "Flexible",
      description: "Well at public scho cheeky bugger grub burke."
    },
    {
      id: 3,
      image: "icon5.png",
      title: "Convinient",
      description: "Well at public scho cheeky bugger grub burke."
    },
    {
      id: 4,
      image: "icon8.png",
      title: "Protected",
      description: "Well at public scho cheeky bugger grub burke."
    },
    {
      id: 5,
      image: "icon6.png",
      title: "World wide",
      description: "Well at public scho cheeky bugger grub burke."
    }
  ],
  HRService: [
    {
      id: "1",
      HRtitles: "Applicativi web performanti",
      HRdescription:
        "Spesso hai l'esigenza di creare qualcosa di non convenzionale, che sia performante e con un utilizzo intuitivo, nulla è impossibile. Sviluppiamo gestionali online, web app, pwa, custom CMS a seconda delle tue esigenze. A noi piacciono le sfide, mettici alla prova!",
      Hicon: "rocket",
      rclass: "pr_70",
      iclass: "icon_one"
    },
    {
      id: "2",
      HRtitles: "Api e sincronizzazioni",
      HRdescription:
        "Creiamo sistemi ed interfacce custom per ogni tipo di applicativo REST, facciamo dialogare il tuo applicativo col resto del mondo e viceversa.",
      Hicon: "control-shuffle",
      rclass: "pl_50 pr_20",
      iclass: "icon_two"
    },
    {
      id: "3",
      HRtitles: "Bot & AI",
      HRdescription:
        "Creiamo bot umanizzati e li sincronizziamo direttamente con il tuo database, i tuoi utenti potranno interagire col sito direttamente in chat, in maniera naturale e dinamica!",
      Hicon: "comments",
      rclass: "pl_70",
      iclass: "icon_three"
    },
    {
      id: "4",
      HRtitles: "Corsi di formazione",
      HRdescription:
        "Le nostre competenze possono essere trasmesse a chiunque. Offriamo corsi di formazione in programmazione (Javascript, PHP, Html, Css) base, intermedia e avanzata. Framework come React, NodeJs, Laravel, Bootstrap ecc..",
      Hicon: "blackboard",
      rclass: "pr_70",
      iclass: "icon_four"
    },
    {
      id: "5",
      HRtitles: "Ethical Hacking",
      HRdescription:
        "Se pensi che il tuo sito o web app abbia delle falle di sicurezza, su richiesta ed esplicito consenso, penetriamo e individuiamo le vulnerabilità che esse presentano, fornendo soluzioni per risolverle e garantire la sicurezza dell'applicativo.",
      Hicon: "unlock",
      rclass: "pl_50 pr_20",
      iclass: "icon_five"
    },
    {
      id: "6",
      HRtitles: "Consulenze e innovazione digitale",
      HRdescription:
        "Portiamo lo sviluppo tecnologico all'interno della tua attività. Assieme ai nostri partner, ti aiuteremo a trovare le giuste soluzioni digitali ai problemi più disparati. Non siamo solo competenti, ma siamo nativi digitali, e la tecnologia fa parte del nostro essere.",
      Hicon: "bolt",
      rclass: "pl_70",
      iclass: "icon_six"
    }
  ],
  rpost: [
    {
      id: "01",
      image: "blog-grid/post_1.jpg",
      ptitle: "Proin gravi nibh velit auctor aliquet aenean.",
      admin: "Admin",
      date: "March 1, 2019"
    },
    {
      id: "02",
      image: "blog-grid/post_2.jpg",
      ptitle: "Proin gravi nibh velit auctor aliquet aenean.",
      admin: "Admin",
      date: "March 10, 2019"
    },
    {
      id: "03",
      image: "blog-grid/post_3.jpg",
      ptitle: "Proin gravi nibh velit auctor aliquet aenean.",
      admin: "Admin",
      date: "jan 10, 2019"
    },
    {
      id: "04",
      image: "blog-grid/post_4.jpg",
      ptitle: "Proin gravi nibh velit auctor aliquet aenean.",
      admin: "Admin",
      date: "March 1, 2019"
    }
  ],
  Blist: [
    {
      id: "01",
      image: "blog-grid/blog_list1.jpg",
      btitle: "Why I say old chap that is, spiffing jolly good.",
      bdescription:
        "Cheeky bugger amongst bugger knees up get stuffed mate what a load of rubbish starkers dropped a clanger say boot, blow off cobblers butty mufty spiffing Eaton the full monty the BBC a, posh chap.",
      pdate: "10",
      Month: "March"
    },
    {
      id: "02",
      image: "blog-grid/blog_list2.jpg",
      btitle: "Why I say old chap that is, spiffing jolly good.",
      bdescription:
        "Cheeky bugger amongst bugger knees up get stuffed mate what a load of rubbish starkers dropped a clanger say boot, blow off cobblers butty mufty spiffing Eaton the full monty the BBC a, posh chap.",
      pdate: "10",
      Month: "June"
    },
    {
      id: "03",
      image: "blog-grid/blog_list3.jpg",
      btitle: "Why I say old chap that is, spiffing jolly good.",
      bdescription:
        "Cheeky bugger amongst bugger knees up get stuffed mate what a load of rubbish starkers dropped a clanger say boot, blow off cobblers butty mufty spiffing Eaton the full monty the BBC a, posh chap.",
      pdate: "10",
      Month: "March"
    },
    {
      id: "04",
      image: "blog-grid/blog_list4.jpg",
      btitle: "Bloke cracking goal the full monty get stuffed mate posh.",
      bdescription:
        "Cheeky bugger amongst bugger knees up get stuffed mate what a load of rubbish starkers dropped a clanger say boot, blow off cobblers butty mufty spiffing Eaton the full monty the BBC a, posh chap.",
      pdate: "01",
      Month: "July"
    }
  ],
  work: [
    {
      id: "01",
      icon: "ti-settings one",
      title: "Premium plugins",
      p:
        "Oxford jolly good cras bugger down the pub blow off well arse tinkety tonk old fruit William bite your arm off haggle, old it's all gone to pot daft no biggie bog.!"
    },
    {
      id: "02",
      icon: "ti-heart-broken two",
      title: "Premium plugins",
      p:
        "Oxford jolly good cras bugger down the pub blow off well arse tinkety tonk old fruit William bite your arm off haggle, old it's all gone to pot daft no biggie bog.!"
    },
    {
      id: "03",
      icon: "ti-target  three",
      title: "Live page builder",
      p:
        "Oxford jolly good cras bugger down the pub blow off well arse tinkety tonk old fruit William bite your arm off haggle, old it's all gone to pot daft no biggie bog.!"
    }
  ]
};
export default ServiceData;
