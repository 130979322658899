import React, { Component } from "react";
import { BrowserRouter as Router, Switch } from "react-router-dom";
/*------ Pages-----*/
import DigitalMarketing from "./Pages/Digital-marketing";
import HomeCRM from "./Pages/Home-CRM";
import About from "./Pages/About";
import Service from "./Pages/Service";
import Contact from "./Pages/Contact";
import Privacy from "./Pages/Privacy";
import ScrollToTopRoute from "./ScrollToTopRoute";

import ReactGA from "react-ga";
ReactGA.initialize("UA-156582063-1");
ReactGA.pageview(window.location.pathname + window.location.search);

class App extends Component {
  componentDidMount() {
    this.props.hideLoader();
  }
  render() {
    return (
      <Router>
        <Switch>
          <ScrollToTopRoute
            exact={true}
            path={"/"}
            component={DigitalMarketing}
          />

          <ScrollToTopRoute path="/servizi" component={Service} />
          <ScrollToTopRoute path="/chi-siamo" component={About} />
          <ScrollToTopRoute path="/parla-con-noi" component={HomeCRM} />
          <ScrollToTopRoute path="/contatti" component={Contact} />
          <ScrollToTopRoute path="/privacy-policy" component={Privacy} />

          {/* <ScrollToTopRoute
            path="/Pages/Digital-marketing"
            component={DigitalMarketing}
          />
          <ScrollToTopRoute
            path="/Pages/Payment-processing"
            component={PaymentProcessing}
          />
          <ScrollToTopRoute
            path="/Pages/HR-Management"
            component={HRManagement}
          />
          <ScrollToTopRoute path="/Pages/Startup" component={Startup} />
          <ScrollToTopRoute path="/Pages/Home-CRM" component={HomeCRM} />
          <ScrollToTopRoute path="/Pages/About" component={About} />
          <ScrollToTopRoute path="/Pages/Service" component={Service} />
          <ScrollToTopRoute path="/Pages/Process" component={Process} />
          <ScrollToTopRoute path="/Pages/Team" component={Team} />
          <ScrollToTopRoute
            path="/Pages/Portfolio-2col"
            component={Portfolio2col}
          />
          <ScrollToTopRoute
            path="/Pages/Portfolio-3col"
            component={Portfolio3col}
          />
          <ScrollToTopRoute
            path="/Pages/Portfolio-fullwidth-4col"
            component={Portfoliofull4col}
          />
          <ScrollToTopRoute
            path="/Pages/PortfolioSingle"
            component={PortfolioSingle}
          />
          <ScrollToTopRoute path="/Pages/Bloglist" component={Bloglist} />
          <ScrollToTopRoute path="/Pages/BlogSingle" component={BlogSingle} />
          <ScrollToTopRoute path="/Pages/Contact" component={Contact} />
          <ScrollToTopRoute path="/Pages/Landing" component={Landing} />*/}
        </Switch>
      </Router>
    );
  }
}

export default App;
