import React, { Component } from "react";
import { Link } from "react-router-dom";
import Reveal from "react-reveal/Reveal";
import Typewriter from "typewriter-effect";

//Svgs
import personsSvg from "../../img/seo/persons.svg";
import board from "../../img/seo/lavagna.svg";

class AgencyBanner extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: true
    };

    this.showAll = this.showAll.bind(this);
  }

  showAll() {
    this.setState({ show: true });
  }

  render() {
    let BannerData = this.props.BannerData;
    return (
      <section className="agency_banner_area bg_color">
        <img
          className="banner_shap h-75"
          src={require("../../img/seo/sfondo.png")}
          alt=""
        />
        <div className="container custom_container">
          <div className="row">
            <div className="col-lg-7 col-md-6 col-sm-8 d-flex align-items-center">
              {this.state.show && (
                <div className="home_bubble">
                  <div className="bubble b_one"></div>
                  <div className="bubble b_two"></div>
                  <div className="bubble b_three"></div>
                  <div className="bubble b_four"></div>
                  <div className="bubble b_five"></div>
                  <div className="bubble b_six"></div>

                  <div className="triangle b_nine">
                    <img
                      src={require("../../img/seo/triangle_three.png")}
                      alt=""
                    />
                  </div>
                </div>
              )}
              <div style={{ zIndex: 999 }} className="agency_content">
                <Reveal effect="fadeInUp">
                  {BannerData.AgencyText.map(Agency => {
                    return (
                      <React.Fragment key={Agency.id}>
                       
                        <h1
                          className="f_700 t_color3 mb_20 wow fadeInLeft"
                          data-wow-delay="0.1s"
                        >
                          Risolviamo i problemi di oggi
                          <br />
                          {"  "}
                          Con le tecnologie di domani
                        </h1>
                        {this.state.show && (
                          <p
                            className="f_400 wow fadeInLeft"
                            data-wow-delay="4.2s"
                          >
                            Siamo giovani creativi, offriamo le nostre
                            competenze digitali per aiutarti a stare sempre un
                            passo davanti agli altri.
                            <br />
                          </p>
                        )}
                      </React.Fragment>
                    );
                  })}
                  <div className="action_btn d-flex align-items-center mt_30 row">
                    <div className="col-lg-6">
                      <Link
                        to="/servizi"
                        className="btn_hover ylabs_btn wow fadeInLeft"
                        style={{ minWidth: 250 }}
                        data-wow-delay="0.5s"
                      >
                        Scopri cosa offriamo 🚀
                      </Link>
                    </div>
                    <div className="col-lg-6">
                      <Link
                        to="/contatti"
                        className="agency_banner_btn_two wow fadeInLeft text-sm-left mt-sm-5"
                        data-wow-delay="0.7s"
                      >
                        Oppure contattaci
                      </Link>
                    </div>
                  </div>
                </Reveal>
              </div>
            </div>
            <div
              style={{ position: "relative" }}
              className="col-lg-5 col-md-6 col-sm-4 text-right clearfix"
            >
              <Reveal effect="fadeInRight">
                <div>
                  <img
                    className="protype_img wow fadeInRight personsImage d-none d-md-block"
                    data-wow-delay="4.3s"
                    src={personsSvg}
                    alt=""
                  />

                  <div className="board-container">
                    <div className="typewriter-container">
                      <Typewriter
                        options={{
                          autoStart: true,
                          loop: false,
                          delay: 60
                        }}
                        onInit={typewriter => {
                          typewriter
                            .typeString(
                              "//You are not meant to understand this <br/>"
                            )
                            .typeString(
                              "let futureComponent = ( < YoungLabs > <br/> <div style='width:20px'></div> < WeDo > <br/>< AmazingStuff onClick=this.setInnovation() />"
                            )
                            .typeString(
                              "<br/> < /WeDo > <br/> < /YoungLabs > )}  "
                            )
                            .pauseFor(250)
                            .typeString("<br/>")
                            .pauseFor(350)
                            .typeString("//🤖 I'm not a robot. I swear")
                            .pauseFor(250)
                            .deleteChars(29)
                            .typeString(
                              "//long long ago; in a galaxy far far away"
                            )
                            .deleteChars(41)
                            .pauseFor(450)
                            .typeString(
                              "setInnovation = () => { <br/> try { <br/> getTheProblem()"
                            )
                            .typeString(
                              "<br/> //Brain power for smart people <br/> this.getBeer() <br/> let idea = this.getIdea() <br/> if(idea) return < futureComponent /> <br/> else this.getAnotherBeer()"
                            )
                            .pauseFor(250)
                            .deleteChars(1)
                            .typeString("'Yes')")

                            .pauseFor(250)
                            .typeString(
                              "<br/> } catch(new Error) { <br/> //If you don't try, you can't get errors <br/> this.getAnotherBeer() } <br/> }"
                            )
                            .pauseFor(250)
                            .typeString(
                              "//If you read this and understood the code, go and apply for a position"
                            )
                            .callFunction(() => {
                              //this.showAll();
                            })
                            .start();
                        }}
                      />
                    </div>
                    <img
                      className="protype_img wow fadeInRight"
                      data-wow-delay="4.3s"
                      src={board}
                      alt=""
                    />
                  </div>
                </div>
              </Reveal>
            </div>
          </div>
          {/*<div className="partner_logo">
            {BannerData.ClientsLogo.map(item => {
              return (
                <Reveal key={item.id} effect="fadeInLeft" duration={1500}>
                  <div className="p_logo_item">
                    <a href="/#">
                      <img
                        src={require("../../img/home3/" + item.image)}
                        alt=""
                      />
                    </a>
                  </div>
                </Reveal>
              );
            })}
        </div>{*/}
        </div>
      </section>
    );
  }
}
export default AgencyBanner;
