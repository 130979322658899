import React from "react";
import { Helmet } from "react-helmet";
import CustomNavbar from "../components/CustomNavbar";
import Breadcrumb from "../components/Breadcrumb";
import AgencyAbout from "../components/About/AgencyAbout";
import Partner from "../components/Partner";
import FooterTwo from "../components/Footer/FooterTwo";
import ServiceData from "../components/Service/ServiceData";
import FooterData from "../components/Footer/FooterData";

const About = () => {
  return (
    <div className="body_wrapper">
      <Helmet>
        <title>Scopri chi siamo</title>
        <meta
          name="description"
          content="Siamo giovani talenti indipendenti, siamo molto aguerriti. Ognuno di noi è specializzato in settori diversi, così da poter ricoprire tutte le esigenze nel mondo digital."
        />
      </Helmet>
      <CustomNavbar
        slogo="sticky_logo"
        mClass="menu_four"
        cClass="custom_container p0"
        nClass="w_menu pl_120 ml-auto mr-auto "
      />
      <Breadcrumb
        breadcrumbClass="breadcrumb_area ylabs_background_gradient"
        imgName="breadcrumb/banner_bg.png"
        Ptitle="Scopri YoungLabs"
        typeString="< YLabsAbout />"
        Pdescription="Siamo giovani e belli, ci credi?"
      />

      <AgencyAbout ServiceData={ServiceData} />
      <Partner pClass="partner_logo_area_two pb_20 bg_color" />
      <FooterTwo FooterData={FooterData} />
    </div>
  );
};
export default About;
