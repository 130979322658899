import React from "react";
import { Helmet } from "react-helmet";
import CustomNavbar from "../components/CustomNavbar";
import Breadcrumb from "../components/Breadcrumb";
import Contacts from "../components/Contacts";
import FooterTwo from "../components/Footer/FooterTwo";
import FooterData from "../components/Footer/FooterData";

const About = () => {
  return (
    <div className="body_wrapper">
      <Helmet>
        <title>Passa da noi</title>
        <meta
          name="description"
          content="Siamo semplici e alla mano, passa da noi per conoscerci di persona."
        />
      </Helmet>
      <CustomNavbar
        slogo="sticky_logo"
        mClass="menu_four"
        cClass="custom_container p0"
        nClass="w_menu pl_120 ml-auto mr-auto"
      />
      <Breadcrumb
        breadcrumbClass="breadcrumb_area ylabs_background_gradient"
        imgName="breadcrumb/banner_bg.png"
        Ptitle="Contattaci"
        typeString="< YLabsContacts />"
        Pdescription="Per berci una birra al bar o per parlare di cose serie noi siamo sempre disponibili, non esitare a contattarci!"
      />
      <Contacts />
      <FooterTwo FooterData={FooterData} />
    </div>
  );
};
export default About;
