import React, { Component } from "react";
import Slider from "react-slick";

class Pserviceslider extends Component {
  render() {
    const settings = {
      dots: false,
      infinite: true,
      speed: 1000,
      arrows: true,
      autoplay: true,
      autoplaySpeed: 5000,
      slidesToShow: 3,
      slidesToScroll: 1,
      centerMode: true,
      centerPadding: "100px",
      responsive: [
        {
          breakpoint: 1250,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
            centerMode: true,
            centerPadding: "100px"
          }
        },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
            centerMode: false,
            centerPadding: "0px"
          }
        },
        {
          breakpoint: 576,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            centerMode: false,
            centerPadding: "0px"
          }
        }
      ]
    };
    return (
      <Slider className="service_carousel" {...settings}>
        <div className="service_item">
          <h4 className="f_600 f_size_20 l_height28 t_color2 mb_20">
            Siti Custom
          </h4>
          <p>
            Sviluppo siti istituzionali ed eCommerce con CMS customizzato, per
            avere funzionalità che differiscono dai Cms OpenSource.
          </p>
          {/*<img
            className="float-right"
            src={require("../../../img/home2/undraw.png")}
            alt=""
          />*/}
        </div>
        <div className="service_item">
          <h4 className="f_600 f_size_20 l_height28 t_color2 mb_20">
            Siti WordPress <br /> su misura
          </h4>
          <p>
            La tua carta d'identità nel web dove il cliente può conoscerti.
            Sviluppo siti in Wordpress e customizzazione temi e plugin.
          </p>
          {/*<img
            className="float-right"
            src={require("../../../img/home2/inbox.png")}
            alt=""
          />*/}
        </div>
        <div className="service_item">
          <h4 className="f_600 f_size_20 l_height28 t_color2 mb_20">
            Campagne Adv
          </h4>
          <p>
            Per vendere e farti trovare utilizziamo strategie legati ai motori
            di ricerca.
          </p>
          {/*<img
            className="float-right"
            src={require("../../../img/home2/file.png")}
            alt=""
          />*/}
        </div>

        <div className="service_item">
          <h4 className="f_600 f_size_20 l_height28 t_color2 mb_20">
            Gestione Social
          </h4>
          <p>
            Gestire un social non significa semplicemente postare foto ma
            riuscire ad educare fidelizzando l'utente.
          </p>
          {/*<img
            className="float-right"
            src={require("../../../img/home2/report.png")}
            alt=""
          />*/}
        </div>

        <div className="service_item">
          <h4 className="f_600 f_size_20 l_height28 t_color2 mb_20">
            E-Mail marketing
          </h4>
          <p>
            Tramite l'email marketing potrai colpire direttamente il tuo cliente
            con un enorme efficacia.
          </p>
          {/*<img
            className="float-right"
            src={require("../../../img/home2/report.png")}
            alt=""
          />*/}
        </div>

        <div className="service_item">
          <h4 className="f_600 f_size_20 l_height28 t_color2 mb_20">
            Acquisisci e vendi
          </h4>
          <p>
            La pista di atterraggio dove acquisire il cliente. La tua finestra
            sul web dove raccogliere contatti interessati al tuo prodotto /
            servizio.
          </p>
          {/*<img
            className="float-right"
            src={require("../../../img/home2/report.png")}
            alt=""
          />*/}
        </div>

        <div className="service_item">
          <h4 className="f_600 f_size_20 l_height28 t_color2 mb_20">Grafica</h4>
          <p>
            Mock-up unici adattati alla tua azienda sia nel campo digitale che
            offline per essere unico e riconoscibile nel tuo settore.
          </p>
          {/*<img
            className="float-right"
            src={require("../../../img/home2/report.png")}
            alt=""
          />*/}
        </div>

        <div className="service_item">
          <h4 className="f_600 f_size_20 l_height28 t_color2 mb_20">
            Video Making
          </h4>
          <p>Drone e video in piano per rappresentare la tua azienda.</p>
          {/*<img
            className="float-right"
            src={require("../../../img/home2/report.png")}
            alt=""
          />*/}
        </div>

        <div className="service_item">
          <h4 className="f_600 f_size_20 l_height28 t_color2 mb_20">Grafica</h4>
          <p>
            Per essere cercati bisogna colpire. La prima cosa da confezionare è
            l'immagine.
          </p>
          {/*<img
            className="float-right"
            src={require("../../../img/home2/report.png")}
            alt=""
          />*/}
        </div>

        <div className="service_item">
          <h4 className="f_600 f_size_20 l_height28 t_color2 mb_20">CRM</h4>
          <p>
            Il gestionale cloud per aziende e liberi professionisti
            personalizzabile per il tuo metodo di lavoro.
          </p>
          {/*<img
            className="float-right"
            src={require("../../../img/home2/file.png")}
            alt=""
          />*/}
        </div>
      </Slider>
    );
  }
}
export default Pserviceslider;
