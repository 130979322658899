import React from "react";
import { Helmet } from "react-helmet";
import CustomNavbar from "../components/CustomNavbar";
import Breadcrumb from "../components/Breadcrumb";
import HRService from "../components/Service/HRService";
import PrototypeService from "../components/Service/Sservice/PrototypeService";
import Partner from "../components/Partner";
import FooterTwo from "../components/Footer/FooterTwo";
import ServiceData from "../components/Service/ServiceData";
import FooterData from "../components/Footer/FooterData";

const Service = () => {
  return (
    <div className="body_wrapper">
      <Helmet>
        <title>Offriamo più di quanto credi</title>
        <meta
          name="description"
          content="Sviluppo applicativi web custom, programmazione ad hoc in ReactJs, NodeJs, Php, Laravel, consulenze sviluppo applicativi, PWA, Agile Development."
        />
      </Helmet>
      <CustomNavbar
        slogo="sticky_logo"
        mClass="menu_four"
        cClass="custom_container p0"
        nClass="w_menu pl_120 ml-auto mr-auto "
      />
      <Breadcrumb
        breadcrumbClass="breadcrumb_area ylabs_background_gradient"
        imgName="breadcrumb/banner_bg.png"
        Ptitle="I nostri servizi"
        typeString="< YLabsServices />"
        Pdescription="Il nostro background tecnico vi stupirà 🥁"
      />
      <HRService ServiceData={ServiceData} />
      <PrototypeService />
      <Partner
        pClass="partner_logo_area_two"
        buttonVisible={true}
        pClasst="pt-0 mb-0"
      />

      <FooterTwo fClass="pt_150" FooterData={FooterData} />
    </div>
  );
};
export default Service;
