import React, { Component } from "react";
import { Link } from "react-router-dom";
import Sectitle from "./Title/Sectitle";
import Aob from "../img/loghi/aob.svg";
import KromoLabs from "../img/loghi/kromolabs.png";

class Partner extends Component {
  render() {
    var { pClass, pClasst, buttonVisible } = this.props;
    return (
      <section className={`${pClass}`}>
        <div className="container">
          <div className={`partner_logo_area_four ${pClasst}`}>
            <Sectitle
              Title="I partners di cui ci fidiamo"
              TitleP="I nostri partners, il nostro team"
              tClass="t_color3"
              sClass="sec_title text-center mb_70"
            />
            <div className="row partner_info">
              <div className="logo_item wow fadeInLeft" data-wow-delay="0.1s">
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="http://aobagency.com/"
                >
                  <img src={Aob} alt="" />
                </a>
              </div>
              <div className="logo_item wow fadeInLeft" data-wow-delay="0.2s">
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.kromolabs.it/"
                >
                  <img src={KromoLabs} className="mt-5" alt="" />
                </a>
              </div>
              {/*<div className="logo_item wow fadeInLeft" data-wow-delay="0.3s">
                <a href=".#">
                  <img src={require("../img/home3/logo_03.png")} alt="" />
                </a>
    </div>*/}
            </div>
          </div>
          {buttonVisible && (
            <div className="row mb-5">
              <Link to="chi-siamo" className="ylabs_btn m-auto">
                Scopri di più
              </Link>
            </div>
          )}
        </div>
      </section>
    );
  }
}

export default Partner;
