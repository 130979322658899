import React from "react";
import { Helmet } from "react-helmet";
import CustomNavbar from "../components/CustomNavbar";
import Breadcrumb from "../components/Breadcrumb";
import FooterTwo from "../components/Footer/FooterTwo";
import FooterData from "../components/Footer/FooterData";

const About = () => {
  return (
    <div className="body_wrapper">
      <Helmet>
        <title>Privacy-policy</title>
        <meta name="description" content="Privacy policy YoungLabs" />
      </Helmet>
      <CustomNavbar
        slogo="sticky_logo"
        mClass="menu_four"
        cClass="custom_container p0"
        nClass="w_menu pl_120 ml-auto mr-auto "
      />
      <Breadcrumb
        breadcrumbClass="breadcrumb_area ylabs_background_gradient"
        imgName="breadcrumb/banner_bg.png"
        Ptitle="Privacy Policy YoungLabs"
        typeString="< YLabsPolicy />"
        Pdescription="Privacy Policy"
      />
      <section className="contact_info_area sec_pad bg_color">
        <div className="container custom_container">
          <div className="row">
            <h3>Privacy Policy</h3>
            <p>Informativa ai sensi dell'art. 13 del Codice della Privacy</p>
            <p>
              <b>
                Ai sensi dell'articolo 13 del codice della D.Lgs. 196/2003, vi
                rendiamo le seguenti informazioni.
              </b>
            </p>
            <p>
              Noi di{" "}
              <strong>
                <span id="sito">https://younglabs.it</span>
              </strong>{" "}
              riteniamo che la privacy dei nostri visitatori sia estremamente
              importante. Questo documento descrive dettagliatamente i tipi di
              informazioni personali raccolti e registrati dal nostro sito e
              come essi vengano utilizzati.
            </p>
            <h3>File di Registrazione (Log Files)</h3>
            <p>
              Come molti altri siti web, il nostro utilizza file di log. Questi
              file registrano semplicemente i visitatori del sito - di solito
              una procedura standard delle aziende di hosting e dei servizi di
              analisi degli hosting.
            </p>
            <p>
              Le informazioni contenute nei file di registro comprendono
              indirizzi di protocollo Internet (IP), il tipo di browser,
              Internet Service Provider (ISP), informazioni come data e ora,
              pagine referral, pagine d'uscita ed entrata o il numero di clic.
            </p>
            <p>
              Queste informazioni vengono utilizzate per analizzare le tendenze,
              amministrare il sito, monitorare il movimento degli utenti sul
              sito e raccogliere informazioni demografiche. Gli indirizzi IP e
              le altre informazioni non sono collegate a informazioni personali
              che possono essere identificate, dunque{" "}
              <strong>
                tutti i dati sono raccolti in forma assolutamente anonima
              </strong>
              .
            </p>
            <div id="cookies" style={{ display: "block" }}>
              <h3>Questo sito web utilizza i Cookies</h3>
              <p>
                I cookies sono piccoli file di testo che vengono automaticamente
                posizionati sul PC del navigatore all’interno del browser. Essi
                contengono informazioni di base sulla navigazione in Internet e
                grazie al browser vengono riconosciuti ogni volta che l’utente
                visita il sito.
              </p>
              <h3>Cookie Policy</h3>
              <p>
                Questo sito utilizza cookies, anche di terze parti, per
                migliorarne l’esperienza di navigazione, consentire a chi naviga
                di usufruire di eventuali servizi online e monitorare la
                navigazione nel sito.
              </p>
              <h3>Come disabilitare i Cookies</h3>
              <p>
                E’ possibile disabilitare i cookies direttamente dal browser
                utilizzato, accedendo alle impostazioni (preferenze oppure
                opzioni): questa scelta potrebbe limitare alcune funzionalità di
                navigazione del sito.
              </p>
              <h3>Gestione dei Cookies</h3>
              <p>
                I cookies utilizzati in questo sito possono rientrare nelle
                categorie descritte di seguito.
              </p>
              <ul>
                <li>
                  <strong>
                    Attività strettamente necessarie al funzionamento
                  </strong>
                  <br />
                  Questi cookies hanno natura tecnica e permettono al sito di
                  funzionare correttamente. Ad esempio, mantengono l’utente
                  collegato durante la navigazione evitando che il sito richieda
                  di collegarsi più volte per accedere alle pagine successive.
                </li>
                <li>
                  <strong>Attività di salvataggio delle preferenze</strong>
                  <br />
                  Questi cookie permettono di ricordare le preferenze
                  selezionate dall’utente durante la navigazione, ad esempio,
                  consentono di impostare la lingua.
                </li>
                <li>
                  <strong>
                    Attività Statistiche e di Misurazione dell’audience (es:
                    Google Analytics)
                  </strong>
                  <br />
                  Questi cookie ci aiutano a capire, attraverso dati raccolti in
                  forma anonima e aggregata, come gli utenti interagiscono con i
                  siti internet fornendo informazioni relative alle sezioni
                  visitate, il tempo trascorso sul sito, eventuali
                  malfunzionamenti. Questo aiuta a migliorare la resa dei siti
                  internet.
                </li>
              </ul>
            </div>
            <div id="fornitori" style={{ display: "none" }}>
              <h3>Fornitori di terze parti</h3>
              <p>
                I fornitori di terze parti, tra cui Google, utilizzano cookie
                per pubblicare annunci in base alle precedenti visite di un
                utente su questo sito.
              </p>
              <p>
                L'utilizzo dei cookie per la pubblicità consente a Google e ai
                suoi partner di pubblicare annunci per gli utenti di questo sito
                (e su altri siti) in base ai dati statistici raccolti su questo
                sito e sui siti web dei partner Google.
              </p>
              <p>
                Gli utenti possono scegliere di disattivare la pubblicità
                personalizzata, visitando la pagina{" "}
                <a href="https://www.google.com/settings/ads" target="_blank">
                  Impostazioni annunci
                </a>
                .
              </p>
              <p>
                Visitando la pagina www.aboutads.info potrai disattivare i
                cookies dei fornitori di terze parti.
              </p>
            </div>

            <h3>Finalità del trattamento</h3>
            <p>
              I dati possono essere raccolti per una o più delle seguenti
              finalità:
            </p>
            <ul>
              <li>
                fornire l'accesso ad aree riservate del Portale e di
                Portali/siti collegati con il presente e all'invio di
                comunicazioni anche di carattere commerciale, notizie,
                aggiornamenti sulle iniziative di questo sito e delle società da
                essa controllate e/o collegate e/o Sponsor.{" "}
              </li>
              <li>
                eventuale cessione a terzi dei suddetti dati, sempre finalizzata
                alla realizzazione di campagne di email marketing ed all'invio
                di comunicazioni di carattere commerciale.{" "}
              </li>
              <li>eseguire gli obblighi previsti da leggi o regolamenti;</li>
              <li>gestione contatti;</li>
            </ul>
            <h3>Modalità del trattamento</h3>
            <p>I dati verranno trattati con le seguenti modalità:</p>
            <ul>
              <li>
                raccolta dati con modalità single-opt, in apposito database;
              </li>
              <li>
                registrazione ed elaborazione su supporto cartaceo e/o
                magnetico;
              </li>
              <li>
                organizzazione degli archivi in forma prevalentemente
                automatizzata, ai sensi del Disciplinare Tecnico in materia di
                misure minime di sicurezza, Allegato B del Codice della Privacy.{" "}
              </li>
            </ul>
            <h3>Natura obbligatoria</h3>
            <p>Tutti i dati richiesti sono obbligatori.</p>
            <div id="trattamento" style={{ display: "none" }}>
              <h3>
                Soggetti a cui dati potranno essere comunicati i dati personali
              </h3>
              <p>I dati raccolti potranno essere comunicati a:</p>
              <ul>
                <li>
                  società e imprese per usi di direct mailing o attività
                  analoghe;
                </li>
                <li>
                  associazioni e fondazioni intenzionate ad acquistare spazi
                  pubblicitari sulle liste o sul sito e/o collegate alla
                  fornitura di un particolare servizio.
                </li>
                <li>
                  soggetti che debbano avere accesso ai dati, come da norme di
                  legge o di normative secondarie e/o comunitarie.
                </li>
              </ul>
            </div>
            <br />
            <h3>Diritti dell'interessato</h3>
            <p>
              Ai sensi ai sensi dell'art. 7 (Diritto di accesso ai dati
              personali ed altri diritti) del Codice della Privacy, vi
              segnaliamo che i vostri diritti in ordine al trattamento dei dati
              sono:
            </p>
            <ul>
              <li>
                conoscere, mediante accesso gratuito l'esistenza di trattamenti
                di dati che possano riguardarvi;
              </li>
              <li>
                essere informati sulla natura e sulle finalità del trattamento
              </li>
              <li>ottenere a cura del titolare, senza ritardo:</li>
              <ul>
                <li>
                  la conferma dell'esistenza o meno di dati personali che vi
                  riguardano, anche se non ancora registrati, e la comunicazione
                  in forma intellegibile dei medesimi dati e della loro origine,
                  nonché della logica e delle finalità su cui si basa il
                  trattamento; la richiesta può essere rinnovata, salva
                  l'esistenza di giustificati motivi, con intervallo non minore
                  di novanta giorni;
                </li>
                <li>
                  la cancellazione, la trasformazione in forma anonima o il
                  blocco dei dati trattati in violazione di legge, compresi
                  quelli di cui non è necessaria la conservazione in relazione
                  agli scopi per i quali i dati sono stati raccolti o
                  successivamente trattati;
                </li>
                <li>
                  l'aggiornamento, la rettifica ovvero, qualora vi abbia
                  interesse, l'integrazione dei dati esistenti;
                </li>
                <li>
                  opporvi in tutto o in parte per motivi legittimi al
                  trattamento dei dati personali che vi riguardano ancorché
                  pertinenti allo scopo della raccolta;
                </li>
              </ul>
            </ul>
            <p>
              Vi segnaliamo che il titolare del trattamento ad ogni effetto di
              legge è:
            </p>
            <ul>
              <li>
                <b>
                  <span id="azienda">YoungLabs</span>
                </b>
              </li>
              <li>
                <span id="indirizzo">Via Cesare Battisti 12</span>
              </li>
              <li>
                <span id="cap">35043</span> - <span id="citta">Monselice</span>{" "}
                (<span id="provincia">PD</span>)
              </li>
              <li>
                Tel/Fax: <span id="telefono">3405688519</span>
              </li>
              <li>
                E-mail: <span id="email">enrico.pettinao@younglabs.it</span>
              </li>
            </ul>
            <p>
              Per esercitare i diritti previsti all'art. 7 del Codice della
              Privacy ovvero per la cancellazione dei vostri dati dall'archivio,
              è sufficiente contattarci attraverso uno dei canali messi a
              disposizione.
            </p>
            <p>
              Tutti i dati sono protetti attraverso l'uso di antivirus, firewall
              e protezione attraverso password.
            </p>
            <h3>Informazioni per i bambini</h3>
            <p>
              Riteniamo importante assicurare una protezione aggiunta ai bambini
              online. Noi incoraggiamo i genitori e i tutori a trascorrere del
              tempo online con i loro figli per osservare, partecipare e/o
              monitorare e guidare la loro attività online. Noi non raccogliamo
              dati personali di minori. Se un genitore o un tutore crede che il
              nostro sito abbia nel suo database le informazioni personali di un
              bambino, vi preghiamo di contattarci immediatamente (utilizzando
              la mail fornita) e faremo di tutto per rimuovere tali informazioni
              il più presto possibile.
            </p>
            <p>
              Questa politica sulla privacy si applica solo alle nostre attività
              online ed è valida per i visitatori del nostro sito web e per
              quanto riguarda le informazioni condivise e/o raccolte. Questa
              politica non si applica a qualsiasi informazione raccolta in
              modalità offline o tramite canali diversi da questo sito web.
            </p>
            <h3>Consenso</h3>
            <p>
              Usando il nostro sito web, acconsenti alla nostra politica sulla
              privacy e accetti i suoi termini. Se desideri ulteriori
              informazioni o hai domande sulla nostra politica sulla privacy non
              esitare a contattarci.
            </p>
          </div>
        </div>
      </section>
      <FooterTwo FooterData={FooterData} />
    </div>
  );
};
export default About;
