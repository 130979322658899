import React, { Component } from "react";
import { Link, NavLink } from "react-router-dom";
import Sticky from "react-stickynode";
import $ from "jquery/";
import "malihu-custom-scrollbar-plugin/";
import "malihu-custom-scrollbar-plugin/jquery.mCustomScrollbar.css";
import "jquery-mousewheel";

class CustomNavbar extends Component {
  componentDidMount() {
    $(window).on("load", function() {
      $(".mega_menu_two .scroll").mCustomScrollbar({
        mouseWheelPixels: 50,
        scrollInertia: 0
      });
    });
  }
  render() {
    var { mClass, nClass, cClass, slogo /*hbtnClass*/ } = this.props;
    return (
      <Sticky top={0} innerZ={9999} activeClass="navbar_fixed">
        <header className="header_area">
          <nav className={`navbar navbar-expand-lg menu_one ${mClass}`}>
            <div className={`container ${cClass}`}>
              <Link className={`navbar-brand ${slogo}`} to="/">
                <img height="30" src={require("../img/logo.png")} alt="" />
                <img height="30" src={require("../img/logo.png")} alt="logo" />
              </Link>
              <button
                className="navbar-toggler collapsed"
                type="button"
                data-toggle="collapse"
                data-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span className="menu_toggle">
                  <span className="hamburger">
                    <span></span>
                    <span></span>
                    <span></span>
                  </span>
                  <span className="hamburger-cross">
                    <span></span>
                    <span></span>
                  </span>
                </span>
              </button>

              <div
                className="collapse navbar-collapse"
                id="navbarSupportedContent"
              >
                <ul className={`navbar-nav menu ml-auto ${nClass}`}>
                  <li className="nav-item dropdown submenu mega_menu mega_menu_two">
                    <Link
                      to="./"
                      title="Home"
                      className="nav-link dropdown-toggle"
                    >
                      Home
                    </Link>
                  </li>

                  <li className="dropdown submenu nav-item">
                    <Link
                      to="servizi"
                      title="Servizi"
                      className="dropdown-toggle nav-link"
                    >
                      Servizi
                    </Link>
                  </li>

                  <li className="dropdown submenu nav-item">
                    <Link
                      to="chi-siamo"
                      title="Chi siamo"
                      className="dropdown-toggle nav-link"
                    >
                      Chi siamo
                    </Link>
                  </li>

                  {/*<li className="dropdown submenu nav-item">
                    <Link
                      to="parla-con-noi"
                      title="Progetti"
                      className="dropdown-toggle nav-link"
                    >
                      Parla con noi
                    </Link>
    </li>*/}

                  <li className="nav-item">
                    <NavLink
                      title="Contatti"
                      className="nav-link"
                      to="contatti"
                    >
                      Contatti
                    </NavLink>
                  </li>
                </ul>
                {/*                <a
                  className={`ylabs_btn arancio-gradient ${hbtnClass}`}
                  href="#yfluent"
                >
                  Scopri yFluent
</a>*/}
              </div>
            </div>
          </nav>
        </header>
      </Sticky>
    );
  }
}

export default CustomNavbar;
