import React, { Component } from "react";
import { Link } from "react-router-dom";
import Stserviceitems from "./Sservice/StserviceItem";
import Reveal from "react-reveal/Reveal";

class MarketingService extends Component {
  render() {
    let ServiceData = this.props.ServiceData;
    return (
      <section className="agency_service_area bg_color pt-5 pt-lg-0 pb-5">
        <div className="container custom_container">
          <Reveal effect="fadeInUp">
            <h2
              className="f_size_30 f_600 t_color3 l_height40 text-center mb_20 wow fadeInUp"
              data-wow-delay="0.2s"
            >
              {ServiceData.STitle}
            </h2>
            <p className="text-center mb_20">
              Offriamo le nostre competenze digitali per aiutarti a stare sempre
              un passo davanti agli altri.
            </p>
          </Reveal>
          <div className="row mt_40 mb_30">
            {ServiceData.Service.map(items => {
              return (
                <Reveal effect="fadeInUp" key={items.id}>
                  <div className="col-lg-3 col-sm-6">
                    <Stserviceitems
                      iShap={items.shape}
                      emoji={items.emoji}
                      Sicon={items.icon}
                      text={items.text}
                      description={items.description}
                      btn={items.btn}
                      bicon={items.bicon}
                    />
                  </div>
                </Reveal>
              );
            })}
          </div>
          <div className="col-lg-12 text-center mt_40">
            <Link
              to="/servizi"
              className="btn_hover ylabs_btn wow fadeInLeft"
              data-wow-delay="0.5s"
            >
              E non solo
            </Link>
          </div>
        </div>
      </section>
    );
  }
}
export default MarketingService;
