import React from "react";
import Fade from "react-reveal/Fade";

const Service = () => {
  return (
    <React.Fragment>
      <section className="seo_features_one sec_pad">
        <div className="container">
          <div className="row flex-row-reverse">
            <div className="col-lg-6">
              <div className="seo_features_img">
                <div className="round_circle"></div>
                <div className="round_circle two"></div>
                <img src={require("../../img/seo/features_img.png")} alt="" />
              </div>
            </div>
            <div className="col-lg-6">
              <Fade bottom cascade>
                <div className="seo_features_content">
                  <h2>
                    Costruiamo assieme il tuo trampolino di lancio digitale
                  </h2>
                  <p>
                    La cura e la passione per il nostro lavoro è essenziale per
                    portarti in alto, possiamo sviluppare qualsiasi cosa,
                    facendo di ogni tuo progetto, un nostro progetto. Portiamo a
                    termine ogni tua idea in semplici, ma essenziali fasi:
                  </p>
                  <div className="media seo_features_item">
                    <div className="icon">
                      <span role="img" aria-label="beer">
                        🍺👔
                      </span>
                    </div>
                    <div className="media-body">
                      <h3>Analizziamo con cura i requisiti insieme a te</h3>
                      <p>
                        Nella fase iniziale parliamo del progetto con te,
                        preferiamo essere informali, ma all'occorrenza
                        indossiamo una cravatta.
                      </p>
                    </div>
                  </div>
                  <div className="media seo_features_item">
                    <div className="icon two">
                      <span role="img" aria-label="beer">
                        👨‍💻🦸
                      </span>
                    </div>
                    <div className="media-body">
                      <h3>Progettiamo e deleghiamo </h3>
                      <p>
                        Ci teniamo a dirti che delegare è il nostro successo. Il
                        nostro team si suddivide in diversi professionisti
                        specializzati su più ambiti: web, software development,
                        digital marketing, design.
                      </p>
                    </div>
                  </div>
                  <div className="media seo_features_item">
                    <div className="icon three">
                      <span role="img" aria-label="beer">
                        ☎️✉️
                      </span>
                    </div>
                    <div className="media-body">
                      <h3>Ti staremo vicini</h3>
                      <p>
                        Non ti lasceremo solo. Sappiamo che cambierai idea col
                        tempo, o che avrai bisogno di assistenza. Siamo sempre
                        disponibili, e quando dormiamo ci penserà il nostro
                        alterego digitale a risponderti
                        <span role="img" aria-label="robot">
                          🤖
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
              </Fade>
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
};

export default Service;
