import React, { Component } from "react";
import axios from "axios";
import Recaptcha from "react-google-invisible-recaptcha";

class Contacts extends Component {
  constructor(props) {
    super(props);

    this.state = {
      name: "",
      message: "",
      email: "",
      send: false,
      errors: {},
      loading: false,
      success: false
    };
  }

  onChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  onResolved = async () => {
    var recaptcha = this.recaptcha.getResponse();

    try {
      var response = await axios.post("formrequest", {
        name: this.state.name,
        email: this.state.email,
        message: this.state.message,
        "g-recaptcha-response": recaptcha
      });

      this.setState({
        success: response.data.success,
        send: true,
        loading: false
      });
      console.log(response);
    } catch (error) {
      console.log(error.response);
      this.recaptcha.reset();
      this.setState({
        success: false,
        send: true,
        loading: false,
        errors: error.response.data.errors
      });
    }
  };

  onSubmit = () => {
    this.setState({ loading: true });
    this.recaptcha.execute();
  };

  render() {
    let { name, message, email, errors } = this.state;
    var errorsAlerts = [];

    Object.values(errors).forEach(value => {
      errorsAlerts.push(
        <div className="alert alert-danger mt-2" role="alert">
          {value.message}
        </div>
      );
    });

    console.log(errorsAlerts);
    return (
      <section className="contact_info_area sec_pad bg_color">
        <div className="container custom_container">
          <div className="row">
            <div className="col-lg-3">
              <div className="contact_info_item">
                <h6 className="f_p f_size_20 t_color3 f_500 mb_20">
                  Indirizzo
                </h6>
                <p className="f_400 f_size_15">
                  Via Cesare Battisti, 12 Monselice (PD)
                </p>
              </div>
              <div className="contact_info_item">
                <h6 className="f_p f_size_20 t_color3 f_500 mb_20">Contatti</h6>
                <p className="f_400 f_size_15">
                  <span className="f_400 t_color3">Telefono:</span>{" "}
                  <a href="tel:3024437488">(+39) 340 5688519</a>
                </p>

                <p className="f_400 f_size_15">
                  <span className="f_400 t_color3">Email:</span>{" "}
                  <a href="mailto:info@younglabs.it">info@younglabs.it</a>
                </p>
              </div>
            </div>
            <div className="contact_form col-lg-9">
              <h2 className="f_p f_size_22 t_color3 f_600 l_height28 mb_40">
                Lasciaci un messaggio
              </h2>

              <div className="row">
                <div className="col-lg-12">
                  <p>
                    Sono{" "}
                    <input
                      type="text"
                      id="name"
                      style={{
                        border: 0,
                        borderBottom: "2px solid #000",
                        background: "transparent",
                        textAlign: "center",
                        width: name.length === 0 ? 50 : name.length * 12
                      }}
                      value={name}
                      onChange={e => this.onChange(e)}
                      name="name"
                      placeholder="Mario"
                    />{" "}
                    , e potrete ricontattarmi qui{" "}
                    <input
                      type="text"
                      name="email"
                      id="email"
                      style={{
                        border: 0,
                        textAlign: "center",
                        borderBottom: "2px solid #000",
                        background: "transparent",
                        width: email.length === 0 ? 150 : email.length * 12
                      }}
                      value={email}
                      onChange={e => this.onChange(e)}
                      placeholder="mario@picol.com"
                    />
                    . Vi contatto per
                    <textarea
                      name="message"
                      id="message"
                      style={{
                        border: 0,
                        marginTop: 25,
                        borderBottom: "2px solid #000",
                        background: "transparent",
                        width: "100%"
                      }}
                      value={message}
                      onChange={e => this.onChange(e)}
                      cols="30"
                      rows="1"
                      placeholder="Sviluppare una nuova web app"
                    ></textarea>
                  </p>
                  <Recaptcha
                    ref={ref => (this.recaptcha = ref)}
                    sitekey="6LeHv9EUAAAAAFn71KkuWrVY9kVwwpBbj7P5Vgay"
                    onResolved={this.onResolved}
                  />
                  <button
                    type="submit"
                    onClick={this.onSubmit}
                    className="ylabs_btn mt-10"
                  >
                    {this.state.loading && (
                      <span
                        className="spinner-border spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                      ></span>
                    )}{" "}
                    Invia
                  </button>
                </div>
              </div>
              {/*<form
                action="#"
                className="contact_form_box"
                method="post"
                id="contactForm"
              >
                <div className="row">
                  <div className="col-lg-6">
                    <div className="form-group text_box">
                      <input
                        type="text"
                        id="name"
                        name="name"
                        placeholder="Mario"
                      />
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-group text_box">
                      <input
                        type="text"
                        name="email"
                        id="email"
                        placeholder="Your Email"
                      />
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div className="form-group text_box">
                      <input
                        type="text"
                        id="subject"
                        name="subject"
                        placeholder="Subject"
                      />
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div className="form-group text_box">
                      <textarea
                        name="message"
                        id="message"
                        cols="30"
                        rows="10"
                        placeholder="Enter Your Message . . ."
                      ></textarea>
                    </div>
                  </div>
                </div>
                <button type="submit" className="btn_three">
                  Send Message
                </button>
              </form>*/}
              {this.state.send && this.state.success && (
                <div className="alert alert-success mt-3" role="alert">
                  Messaggio inviato con successo! Ti contatteremo al più presto
                  :)
                </div>
              )}

              {this.state.send &&
                !this.state.success &&
                errorsAlerts.map((errorAlert, index) => (
                  <div key={index}> {errorAlert} </div>
                ))}
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default Contacts;
