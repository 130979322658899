import React, { Component } from "react";
import { Link } from "react-router-dom";
import HRServiceItems from "./HRServiceItems";

class HRService extends Component {
  render() {
    let ServiceData = this.props.ServiceData;
    return (
      <div>
        <section className="prototype_service_info">
          <div className="symbols-pulse active">
            <div className="pulse-1"></div>
            <div className="pulse-2"></div>
            <div className="pulse-3"></div>
            <div className="pulse-4"></div>
            <div className="pulse-x"></div>
          </div>
          <div className="container custom_container">
            <h2 className="f_size_30 f_600 t_color3 l_height45 text-center mb_90">
              Ti verrà sempre offerto il massimo.
            </h2>
            <div className="row p_service_info">
              {ServiceData.HRService.map(item => {
                return (
                  <HRServiceItems
                    HRtitle={item.HRtitles}
                    HRdescription={item.HRdescription}
                    Hicon={item.Hicon}
                    rclass={item.rclass}
                    iclass={item.iclass}
                    key={item.id}
                  />
                );
              })}
            </div>
          </div>
        </section>
        <section className="seo_call_to_action_area sec_pad ylabs_background_gradient">
          <div className="container">
            <div className="seo_call_action_text">
              <h2>
                Siamo persone alla buona, <br /> non avere paura di contattarci{" "}
                <span role="img" aria-label="emoji">
                  😁
                </span>
              </h2>
              <Link to="/contatti" className="about_btn">
                Beviamoci un caffè assieme
              </Link>
            </div>
          </div>
        </section>
      </div>
    );
  }
}
export default HRService;
