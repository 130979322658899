import React, { Component } from "react";
import { Link } from "react-router-dom";

class AgencyAction extends Component {
  render() {
    return (
      <section className="action_area_three sec_pad ylabs_background_gradient">
        <div className="curved "></div>
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="action_content text-center">
                <h2 className="f_600 f_size_30 l_height45 mb_40">
                  Pronto per innovare insieme a noi?
                </h2>
                <Link
                  to="contatti"
                  title="Contattaci"
                  className="about_btn white_btn arancio-gradient wow fadeInLeft"
                  data-wow-delay="0.3s"
                >
                  Contattaci
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default AgencyAction;
