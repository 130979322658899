import React from "react";
import { Helmet } from "react-helmet";
import CustomNavbar from "../components/CustomNavbar";
import AgencyBanner from "../components/Banner/AgencyBanner";
import BannerData from "../components/Banner/BannerData";
import MarketingService from "../components/Service/MarketingService";
import ServiceData from "../components/Service/ServiceData";

import AgencyAction from "../components/AgencyAction";
import Service from "../components/Service/ServiceNew";
//import AppFeatures from "../components/Features/AppFeatures";
//import Integrations from "../components/Features/Integrations";
import FooterTwo from "../components/Footer/FooterTwo";
import FooterData from "../components/Footer/FooterData";

const DigitalMarketing = () => {
  return (
    <div className="body_wrapper">
      <Helmet>
        <title>
          Young Labs, risolviamo i problemi di oggi con le tecnologie di domani
        </title>
        <meta
          name="description"
          content="Siamo giovani creativi, offriamo le nostre competenze digitali per aiutarti a stare sempre un passo davanti agli altri."
        />
      </Helmet>
      <CustomNavbar
        mClass="menu_four"
        cClass="custom_container p0"
        nClass="pl_120 mr-auto ml-auto"
        hbtnClass="menu_cus"
      />
      <AgencyBanner BannerData={BannerData} />
      <MarketingService ServiceData={ServiceData} />
      <Service />
      {/*<AppFeatures />
      <Integrations />*/}
      <AgencyAction />

      <FooterTwo FooterData={FooterData} />
    </div>
  );
};
export default DigitalMarketing;
